
import Vue from 'vue'

export default Vue.extend({
  name: 'HelloWorld',

  data: () => ({
    formdata:{
      name: "",
      department: "",
      position: "",
      address: "",
      company: "",
      phone: "",
      mail: "",
      etc: "",
    },


    search: "", 
    headers: [  
        
      { 
        text: 'タイトル' ,
        align: 'center',
        value: 'title',
        
      },
      { text: 'キーワード', align: "center", value: 'keyword' },
      { text: '担当会社', align: "center", value: 'company' },
      { text: '更新日', align: 'center', sortable: true, value: 'updated_at' },

    ],

    sendingFlg: false,
    finishSendFlg: false,
    sendErrorFlg: false,
    notSelectitemFlg: false,
    desserts: []
  }),

  mounted: async function () {
    const data = await (await fetch("/api/documents")).json();
    this.desserts = data; 
  },

  methods: {
    req(value:string){
      if(value.length==0){
        return '必須項目です'
      }
      return true;
    },

    limit10(value:string){
      if(value.length>=10){
        return '10文字以内で入力してください'
      }
      return true;

    },


    mail(value:string){
      if(value.length>=10){
        return 'メールアドレスに「＠」を挿入してください'
      }
      return true;

    },
   
    async requestForm() {
      try {
        this.sendingFlg = true;
        
        if (!this.$refs.test_form.validate()) {
          return;
        }


        const formdata = JSON.parse(JSON.stringify(this.formdata));
        formdata.ids = this.computedTable.map((d:any) => d.id)
        if (formdata.ids.length == 0) {
          this.notSelectitemFlg = true
          return
        }
        const param = {
            method: 'POST', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formdata)

        
        }
        const res = await fetch("/api/request/documents", param)
        if (res.status == 200){
          this.finishSendFlg = true;
        } else {
          this.sendErrorFlg = true;
        }
      } catch{
        this.sendErrorFlg = true;
      } finally {
        this.sendingFlg = false;
      }
    }
  },


  computed: {
    
    computedTable: function (): any {
      if (this.search.length == 0) {
        return this.desserts;
      }
      
      const searchKeywordRegex = this.search.replaceAll(/(,| |\u3000|、)+/g, "|").replace(/\|$/g, "")
      return this.desserts.filter((d:any) => {
        const tmp = (d.title + d.keyword + d.company + d.updated_at)
        return tmp.match(new RegExp(searchKeywordRegex, "i"))
      })
    }

   
  }
})


   



